<template>
  <order :pagination="pagination"></order>
</template>

<script>

  import Order from '@/components/Order'

  export default {
    name: '',
    components: {
      Order
    },
    mounted() {
    },
    data: function () {
      return {

        pagination: {
        },
      }
    },

  }
</script>
