<template>
  <div class="order">
    <el-row class="order-filter">
      <el-form label-width="auto" size="mini">
        <el-col :span="14">
          <el-form-item label="订单搜索:">
            <div class="order-filter-search">
              <el-select v-model="page.uuidType">
                <el-option v-for="item in uuidTypes"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>

              <el-input placeholder="请输入订单编号" class="order-input-filter" clearable
                        v-model="uuid"
                        prefix-icon="el-icon-search"></el-input>

              <el-button type="primary" size="small" @click="loadOrder">筛选</el-button>
              <el-button type="text" size="small" @click="showFilter=!showFilter">{{showFilter? '收起': '更多'}}筛选
              </el-button>
            </div>

          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="制作时间:">
            <time-range @change="handleMakeTime"/>
          </el-form-item>
        </el-col>

        <template v-if="showFilter">
          <el-col :span="8">
            <el-form-item label="来源渠道:">
              <el-select placeholder="请选择来源渠道" v-model="page.formChanel" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in fromTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单类型:">
              <el-select placeholder="请选择订单类型" class="order-input-filter" v-model="page.orderType"
                         @change="loadOrder">
                <el-option v-for="item in orderTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="订单状态:">
              <el-select placeholder="请选择订单状态" v-model="page.status" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in statusOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="支付渠道:">
              <el-select placeholder="请选择支付渠道" v-model="page.payChanel" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in payChanelOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="支付类型:">
              <el-select placeholder="请选择支付类型" v-model="page.payChanelType" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in payChanelTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支付状态:">
              <el-select placeholder="请选择支付状态" v-model="page.payStatus" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in payStatusOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>


      </el-form>
    </el-row>

    <div>
      <el-table :data="orders">
        <empty slot="empty"></empty>
        <el-table-column label="订单编号" min-width="320">
          <template slot-scope="scope">
            <div class="user-info">
              <p>订单编号: {{scope.row.uuid}}</p>
              <p style="margin-top: 4px">交易编号: {{scope.row.traditionUuid}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" align="center" prop="saleName" min-width="130"></el-table-column>
        <el-table-column label="饮品信息" align="center" prop="name" min-width="160">
          <template slot-scope="scope">
            <div class="user-info">
              {{scope.row.sourceName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="制作状态" align="center">
          <template slot-scope="scope">
            <div class="user-info">
                            <span :class="[{ offline: scope.row.status ==0 }, { fault: scope.row.status ==2 },
                                    { normal: scope.row.status ==1 }]">
                                   <span class="circle"
                                         v-show="scope.row.status ==1"></span> {{scope.row.status | filterOrderStatus}}
                                </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="来源" align="center">
          <el-table-column
            label="订单类型"
            align="center"
            :loading="loading"
            width="80">
            <template slot-scope="scope">
              <div class="user-info">
                            <span :class="[{ testPayMake: scope.row.orderType === 1 },
                             { notPayMake: scope.row.orderType === 2 },
                                    { payMake: scope.row.orderType === 3 }]">
                                   {{scope.row.orderType | filterMakeStyle}}
                                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="来源通道">
            <template slot-scope="scope">
            <span >
                                   {{scope.row.formChanel | filterFormChanel}}
                                </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="支付金额" align="center">
          <el-table-column
            label="订单金额"
            align="center"
            width="80">

            <template slot-scope="scope">
              <span>{{(parseInt(scope.row.money) / 100).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="成交金额"
            align="center"
            width="80">

            <template slot-scope="scope">
              <span>{{(parseInt(scope.row.realPayPrice) / 100).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="原始金额"
            align="center"
            width="80">

            <template slot-scope="scope">
              <span>{{(parseInt(scope.row.originalPrice) / 100).toFixed(2) }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="支付方式" align="center">
          <el-table-column
            label="通道"
            align="center"
            width="80">

            <template slot-scope="scope">
            <span :class="[
                             { wechatPay: scope.row.payChanel === 2 },
                                    { aliPay: scope.row.payChanel === 3 }]">{{scope.row.payChanel | filterPay }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="渠道"
            align="center"
            width="80">

            <template slot-scope="scope">
              <span>{{scope.row.payChanelType | filterPayType }}</span>
            </template>
          </el-table-column>
        </el-table-column>


        <el-table-column label="退款状态" align="center">
          <template slot-scope="scope">
            <div class="user-info">
                            <span :class="[{ offline: scope.row.payStatus ==0 },
                                    { fault: scope.row.payStatus ==1 },
                                    { normal: scope.row.payStatus ==2 }]">
                                   <span class="circle" v-show="scope.row.payStatus ==1"></span>
                                {{scope.row.payStatus | filterReturnStatus}}
                                </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="订单时间" align="center" min-width="140">
          <template slot-scope="scope">
            <span style="font-size: 12px">{{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80" fixed="right">
          <template slot-scope="scope">

                        <el-button type="text" :disabled="scope.row.traditionUuid.indexOf('yk') >= 0"
                                   @click="handleRefund(scope.row, scope.$index)"
                              v-if="scope.row.orderType === 3"
                              :class="{disable:  scope.row.payStatus === 2 }">
                            <i class="iconfont icontuikuan"></i>  退款</el-button>
            <el-button  :disabled="true" type="text"
                        v-else><i class="iconfont icontuikuan"></i>  退款</el-button>
            <!--                        <span @click="handleEdit(scope.row, scope.$index)" class="order-delete order">-->
            <!--                            <i class="iconfont iconshanchu"></i>  删除</span>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        class="pagination"
        :current-page="page.curPage"
        :page-size="page.limit"
        layout="total, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
    </div>
  </div>

</template>

<script>
  export default {
    name: '',
    props: {
      pagination: {
        type: Object,
        default: () => {
          return {
          }
        }
      }
    },
    mounted: function () {
      this.loadOrder()
    },
    data: function () {
      return {
        orders: [],
        showFilter: false,
        loading: false,
        uuid: '',
        fromTypeOption: [
          {label: '全部渠道', value: ''},
          {label: '支付页面', value: 'H5'},
          {label: '微信小程序', value: 'WxMini'},
          {label: '支付宝小程序', value: 'AliMini'},
        ],
        orderTypeOption: [
          {label: '所有订单', value: 0},
          {label: '测试订单', value: 1},
          {label: '无支付订单', value: 2},
          {label: '支付订单', value: 3},
        ],
        statusOption: [
          {label: '全部', value: 0},
          {label: '制作成功', value: 1},
          {label: '制作失败', value: 2},
        ],
        payChanelOption: [
          {label: '所有支付', value: 0},
          {label: '无支付', value: 1},
          {label: '微信支付', value: 2},
          {label: '支付宝', value: 3},
        ],
        payChanelTypeOption: [
          {label: '所有类型', value: 0},
          {label: '微信子商户支付', value: 1},
          {label: '咖博士微信支付', value: 2},
          {label: '微信商户支付', value: 3},
          {label: '咖博士支付宝', value: 4},
          {label: '支付宝支付', value: 5},
        ],
        payStatusOption: [
          {label: '所有状态', value: 0},
          {label: '已收款', value: 1},
          {label: '已退款', value: 2},
        ],
        uuidTypes: [
          {label: '订单编号', value: 0},
          {label: '支付编号', value: 1},
          {label: '设备名称', value: 2},
          {label: '饮品名称', value: 3},
        ],
        makeTimeRange: [],
        page: {
          current: 0,
          total: 0,
          start: 0,
          limit: 10,
          startAt: 0,
          endAt: 0,
          uuidType: 0,
          uuid: '',
          tradeUuid: '',
          saleName: '',
          payChanel: 0,
          payChanelType: 0,
          fromChanel: '',
          status: 0,
          payStatus: 0,
          orderType: 0,
        }
      }
    },
    filters: {
      filterReturnStatus(returnStatus) {
        switch (returnStatus) {
          case 0:
          case 1:
            return '未退款'
          case 2:
            return '已退款'
        }
      },
      filterOrderStatus(status) {
        switch (status) {
          case 0:
            return '制作中'
          case 2:
            return '制作失败'
          case 1:
            return '成功'
        }
      },
      filterPay(style) {
        switch (style) {
          case 1:
            return '无支付'
          case 2:
            return '微信支付'
          case 3:
            return '支付宝'
        }
      },

      filterPayType(style) {
        switch (style) {
          case 1:
            return '微信子商户'
          case 2:
            return '咖博士微信'
          case 3:
            return '微信商户'
          case 4:
            return '咖博士支付宝'
          case 5:
            return '支付宝'
        }
      },
      filterFormChanel(ch) {
        switch (ch) {
          case 'H5':
            return '移动网页'
          case 'WxMini':
            return '微信小程序'
          case 'AliMini':
            return '支付宝小程序'
        }
      },

      filterMakeStyle(style) {
        switch (style) {
          case 1:
            return '测试'
          case 2:
            return '无支付'
          case 3:
            return '支付'
        }
      }
    },
    watch: {
      pagination: {
        deep: true,
        handler: function () {
          this.loadOrder()
        }
      }
    },
    methods: {
      handleMakeTime(ts) {
        this.page.startAt = ts[0]
        this.page.endAt = ts[1]
        this.loadOrder()
      },

      handleCurrentChange(value) {
        this.handleUuidType()
        this.loading = true
        this.page.curPage = value
        this.page.start = (value - 1) * this.page.limit
        this.$api('mkt.order.list',{...this.pagination, ...this.page}).then(res => {
          this.orders = res || []
          this.loading = false
        })
      },

      handleUuidType() {
        this.page.tradeUuid = ''
        this.page.saleName = ''
        this.page.sourceName = ''
        this.page.uuid = ''
        switch (this.page.uuidType) {
          case 0:
            this.page.uuid = this.uuid
            break
          case 1:
            this.page.tradeUuid = this.uuid
            break
          case 2:
            this.page.saleName = this.uuid
            break
          case 3:
            this.page.sourceName = this.uuid
            break
        }

      },

      loadOrder() {
        let vue = this
        this.loading = true

        this.handleUuidType()
        this.$api('mkt.order.union',{...this.pagination, ...this.page}).then(res => {
          vue.orders = res.list || []
          vue.page.total = parseInt(res.total)
          this.loading = false
        })
      },

      handleRefund(row, index) {
        this.$confirm('此操作将会退还订单金额, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api('mkt.order.refund',{
            orderUuid: row.orderUuid,
            deviceUuid: row.saleUuid,
            fee: parseInt(row.money)
          }).then(() => {
            this.$message.success('退款成功')
            this.orders[index].payStatus = 2
          })
        }).catch(() => {

        })

      },
    },
  }
</script>

<style lang="scss" scoped>
  .order {
    &-filter {
      margin: $large-space $middle-space;

      &-search {
        display: flex;
        align-items: center;

        .el-input {
          margin-left: $small-space;
          margin-right: $middle-space;
        }
      }


    }


    .el-table {
      thead {
        tr {
          th {
            border-bottom: 1px solid;

          }
        }
      }

    }

    .order {
      padding: 0 .3rem;
    }

    .order-edit, .order-delete {
      cursor: pointer;
      display: inline-block;

      i {
        margin-right: 2px;
      }
    }

    .order-edit:hover, .order-delete:hover {
      //color: $f_color;
    }

    .order-edit {
      color: $brand-warning;
    }

    .order-edit:hover {
    }

    .order-delete {
      color: $brand-danger;
    }

    .order-delete:hover {
    }

    .disable {
      cursor: not-allowed;
      border: none;
    }

    .disable:hover {
      //    color: $f_50_color;
      //   background-color: $bg_six_color;
    }

    .circle {
      // border-radius: $small_font_size;
      // width: $small_font_size;
      // height: $small_font_size;
      display: inline-block;
      margin-right: 5px;
    }

    .wechatPay {
      color: #44b549;
    }

    .aliPay {
      color: #00aaee;
    }

    .testPayMake {
      color: #5AD8A6;
    }

    .notPayMake {
      color: #F6BD16;
    }

    .payMake {
      color: #08d55b;
    }

    .offline {
      color: #5B8FF9;

      .circle {
        background-color: #5B8FF9;
      }
    }

    .normal {
      color: #5AD8A6;

      .circle {
        background-color: #5AD8A6;
      }
    }

    .warning {
      color: #F6BD16;

      .circle {
        background-color: #F6BD16;
      }
    }

    .fault {
      color: #E8684A;

      .circle {
        background-color: #E8684A;
      }
    }
  }
</style>
